@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .header {
        @apply h-[90px] bg_base w-full fixed top-0 left-0 z-[1024];
    }
    .header {
        @apply h-[90px] bg-white border-b  shadow-sm w-full fixed top-0 left-0 z-[60];
    }
    .bg_base {
        @apply bg-[#0d44b0];
    }
    .color_base {
        @apply text-[#002451];
    }
    .flex_center {
        @apply flex justify-center items-center;
    }
    .title_text {
        @apply text-[30px] font-bold color_base;
    }
    .section {
        @apply h-[120px] w-[300px] flex items-center justify-center border-b-2;
    }
    .input2 {
        @apply w-[350px] h-[40px] text-[16px] py-2 px-1 border-b focus:outline-none hover:outline-none hover:border-[#002451] bg-white;
    }
    .button-color2 {
        @apply bg-[#002451] text-white border rounded-2xl;
    }
    .button_off {
        @apply bg-gray-300 w-[124px] py-2 rounded-lg text-white font-normal;
    }
    .button_on {
        @apply bg_base w-[124px] py-2 rounded-lg text-white font-normal cursor-pointer;
    }
    .bg_base {
        @apply bg-[#0d44b0];
    }
    .base_button {
        @apply bg-white;
    }
    .color_base {
        @apply text-[#0d44b0];
    }
    .flex_center {
        @apply flex justify-center items-center;
    }
    .title_text {
        @apply text-[30px] font-bold color_base;
    }
    .input {
        @apply w-[200px] border-b pt-4 focus:outline-none hover:outline-none;
    }
    .m_input {
        @apply w-[100px] border-b pt-4 focus:outline-none hover:outline-none
    }
    .button-color {
        @apply border w-[120px] h-[30px] rounded-lg bg_base;
    }
    .m_button {
        @apply border w-[120px] h-[40px] text-[12px] mt-4 font-bold bg-[#f4e5b2] text-center flex items-center justify-center;
    }
    .button {
        @apply border w-[200px] h-[40px] mt-4 font-bold bg-[#f4e5b2] text-center flex items-center justify-center;
    }
    .button_on {
        @apply bg_base w-[124px] py-2 rounded-lg text-white font-normal cursor-pointer;
    }
}
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px transparent inset;
}
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}
